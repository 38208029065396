<template>
  <zmAppPage :show-footer="false" :showHeaderLeft="true" title="听写练习">
    <div class="extend zm-flex is-column pd15">
      <div class="top flex-1 tc">
        <div class="fs20 fb mb10 text-primary">请写出你听到的英文句子的中文答案</div>
        <div class="input-answer">
          <van-field v-model="answer" label="" placeholder="请输入答案" clearable />
        </div>
        <div class="answer mt20" v-if="!playFinish">
          <div>参考答案</div>
          <div class="answer-text-box fs18 mt20">
            <div class="answer-text" v-if="showAnswer">{{ currentWord.textCn }}</div>
          </div>
          <div class="line tc"></div>
        </div>
        <div v-else class="mt20">
          <div class="tc mb10 fs18 text-primary">单词一轮听写完毕</div>
          <div class="mb15">
            本单元共<span class="text-primary fs18">{{ wordList.length }}</span
            >个单词,你共答对<span class="text-primary fs18">{{ rightCount }}</span
            >个
          </div>
          <div class="mb10">{{resultTip}}</div>
          <van-button icon="share-o" type="info" @click="init()">重新开始</van-button>
        </div>
      </div>
      <listerner-btn @repeat="playWord" @next="onNext" @answer="onAnswer"></listerner-btn>
    </div>
  </zmAppPage>
</template>

<script>
import ListernerBtn from '@/pages/unitWord/components/ListernerBtn';
import { mapGetters } from 'vuex';
import {randomNumberOne} from '@/utils/src/tools';
export default {
  name: 'ReadRecord',
  components: { ListernerBtn },
  data() {
    return {
      randomIndex: 0,
      isPlay: false,
      showAnswer: false,
      playFinish: false,
      sec: 0,
      answer: '',
      secTemp: 0,
      rightCount: 0,
      readingList: []
    };
  },
  computed: {
    ...mapGetters(['wordList']),
    resultTip() {
      const res = this.wordList.length ? this.rightCount / this.wordList.length : 0;
      let text = '你这么棒,你妈妈知道吗?';
      if (res < 0.5) {
        text = "别放弃,继续努力!";
      } else if (res < 0.8) {
        text = "哎约不错哦,加油!";
      } else if (res < 1) {
        text = "秀儿,你太棒了,还有空间哦!";
      }
      return text;
    },
    currentWord() {
      return this.readingList[this.randomIndex] || {text: '',textCn: ''};
    }
  },
  watch: {},
  created() {
      this.init();
  },
  methods: {
    init() {
      this.initWordList();
      this.onChange('1');
    },
    initWordList() {
      this.rightCount = 0;
      this.readingList = [...this.wordList];
      this.playFinish = false;
    },
    cutSec(num) {
      this.sec = num + '"';
      num -= 1;
      let timer = null;
      if (num > -1) {
        let timer = setTimeout(() => {
          this.cutSec(num);
        }, 1000);
      } else {
        this.sec = this.secTemp;
        this.isPlay = false;
        clearTimeout(timer);
        timer = null;
      }
    },
    onNext() {
      this.onAnswer();
      this.onChange(false);
    },
    play(audioSrc) {
      let audio = new Audio(),
        numArr = this.sec.split('"'),
        vm = this;
      audio.autoplay = true;
      this.isPlay = true;
      this.$nextTick(function() {
        vm.cutSec(+numArr[0]);
        audio.src = audioSrc;
      });
    },
    playWord(item = this.currentWord) {
        this.sec = Math.ceil(item.trackLength / 1000) + '"';
        this.secTemp = Math.ceil(item.trackLength / 1000) + '"';
        this.play(item.audio);
    },
    onAnswer() {
      let answer = this.answer.trim();
      const isRight = answer && this.currentWord.textCn.indexOf(answer) === 0;
      if (isRight) this.rightCount++;
      let res = isRight ? '答对了' : '答错了';
      this.$toast(res);
      this.showAnswer = true;
    },
    onChange(isFirst) {
        this.showAnswer = false;
        this.answer = "";
         if (!isFirst) this.readingList.splice(this.randomIndex,1);
        if (this.readingList.length < 1) {
          this.playFinish = true;
        }
        this.randomIndex = randomNumberOne(0, this.readingList.length - 1);
        this.playWord(this.currentWord);
    }
  }
};
</script>
<style lang="scss" scoped>
.line {
  height: 1px;
  line-height: 1px;
  &:after {
    content: '';
    display: inline-block;
    width: 50%;
    height: 100%;
    background: $--color-primary;
  }
}
.answer-text-box {
  height: 0.5rem;
}
</style>
