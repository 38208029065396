<template>

  <div class="read-tab zm-flex is-align-flex-end">
    <div class="item flex-1" @click="onRepeat">
      <zm-icon icon-class="repeat-fill" class="fs32 mb10"></zm-icon>
      <div class="name">重听单词</div>
    </div>
    <div class="item flex-1 record-btn" @click="onNext">
      <zm-icon icon-class="next-fill" class="record-icon mb10"></zm-icon>
      <div class="name">下一个单词</div>
    </div>
    <div class="item flex-1" @click="onAnswer">
      <zm-icon icon-class="answer-fill" class="fs32 mb10"></zm-icon>
      <div class="name">参考答案</div>
    </div>
  </div>
</template>
<script>
import { longpress } from '@/directives';
import { mapGetters } from 'vuex';
import Recorderx, { ENCODE_TYPE } from 'recorderx';
const recordTime = '12';
export default {
  name: 'ReadBtn',
  components: {},
  directives: {
    longpress
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    audioArr: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      myAudio: new Audio(),
      btnText: '开始录音',
      rc: 0,
      voiceTemp: null,
      wavTemp: null,
      wavFileUrl: '',
      recording: false,
      isPlaying: false,
      recordTime: 12,// 录音时长
      secTemp: 0,
      sec: 0
    };
  },
  computed: {
    ...mapGetters(['unitIndex', 'unitListLength'])
  },
  watch: {
  },
  created() {},
  methods: {
    onAnswer() {
      this.$emit('answer');
    },
    onRepeat() {
      this.$emit('repeat');
    },
    onNext() {
      this.$emit('next');
    }
  }
};
</script>
<style lang="scss" scoped>
.read-tab {
  //   background: darken($--color-primary, 5%);
  padding: 0.5rem 0.5rem;
  .circle-play {
    font-size: 1rem;
  }
  .item {
    text-align: center;
    font-size: 0.24rem;
    color: $--color-primary;
  }
  .audio-box {
    display: none;
  }
  .record-btn {
    .record-icon {
      font-size: 1rem;
    }
  }
}
</style>
